.aboutUs{
  margin-top: 120px;
  margin-bottom: 40px;
}
.aboutUs .about-us{
  display: flex;
  margin-bottom: 3rem;
}
.aboutUs .about-us .description{
  background: #F8F8F8;
  padding: 73px 75px;

}
.aboutUs .clients{
  /* background-color: #F9F9FB;
  border: 1px solid #B9BBC6; */
  border-radius: 15px;
  width: 95%;
  margin: auto;
  margin-top: 3rem;
}
.aboutUs .row{
  --bs-gutter-x: 0;
}
.aboutUs .tab{
  margin-bottom: 1rem;
  background-color: var(--orange-bg-color);
  font-size: 16px;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}
.aboutUs .tab.active{
  background-color: var(--primary-color);
  color: #fff;
}
.aboutUs .type-title{
  text-align: center;
  margin-bottom: 40px;
}
.aboutUs .type-title span{
  font-size: 72px;
  font-weight: 500;
  line-height: 86.54px;
  display: block;
  color: #000;
}
@media only screen and (max-width: 500px) {
  .aboutUs {
    padding: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
  .aboutUs-tabs{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .aboutUs-tab{
    display: flex;
    gap: 18px;
    overflow-x: scroll;
  }
  .aboutUs .tab{
    text-wrap: nowrap;
  }
  .aboutUs .type-title span {
    font-size: 40px;
    line-height: 1;
  }
  .aboutUs .about-us{
    flex-direction: column;
  }
  .aboutUs .about-us .description {
    background: #F8F8F8;
    padding: 40px 20px;
    width: 100% !important;
  }
  .aboutUs .about-us .image{
    width: 100%!important;
    margin-top: 1rem;
  }
}