.categories{
  min-height: 100vh;
  margin-top: 104px;
  overflow: hidden;
}
.wholesale-alert{
  background-color: #FFBD0033;
  padding: 32px;
  border-radius: 20px;
}
.wholesale-alert p{
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #000;
}
.categories .products{
  margin-top: 0px;
  padding: 0;
}
.categories .tabs{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 24px;
  overflow-x: scroll;
}
.categories .tabs .tab{
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 8px 12px;
  text-align: center;
  line-height: 1.2;
  font-size: 14px;
  cursor: pointer;
  text-wrap: nowrap;
}
.categories .tabs .tab.active{
  background-color: var(--primary-color);
  color: #fff;
}
.modal-header{
  justify-content: space-between;
}
.modal-header .btn-close{
  margin: 0;
}
.inquiry-modal{
  position: fixed;
    width: 100%;
    background: hsla(230, 9%, 13%, 0.7);
    right: 0;
    left: 0;
    bottom: 0;
    padding: 24px 75px;
}
.inquiry-modal button{
  width: 270px;
}
.inquiry-modal p{
      font-size: 18px;
    color: #fff;
    line-height: 2;
}
.en .categories .products{
  padding-right: 75px;
  padding-left: 0;
}
.ar .categories .products{
  padding-left: 75px;
  padding-right: 0;
}
.categories hr{
  color: #D0D0D0;
}
.type-title{
  font-size: 32px;
  line-height: 43.39px;
  font-weight: 700;
  margin-top: 12px;
  margin-bottom: 24px;
}
.type-title span{
  font-size: 18px;
  font-weight: 400;
  line-height: 20.93px;
  color: #848484;
}


.filter-section {
  padding: 0 14px 31px;
  background-color: #F8F8F8;
}
.filter-section h5{
  margin-left: 75px;
  padding-top: 32px;
  font-size: 24px;
  line-height: 28.85px;
}
.brands{
  padding-left: 75px;
}
.brands .brand-title{
  color: #848484;
  font-size: 18px;
  margin-bottom: 16px;
  display: block;
  font-weight: 500;
}
.brands .search{
  height: 40px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
}
.brands .brand-labels{
  gap: 8px;
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
}
.brands .brand-labels input{
  height: 18px;
  width: 18px;
  outline: none;
	content: none;	
}

@media only screen and (max-width: 500px) {
  .wholesale-alert{
    padding: 10px
  }
  .wholesale-alert p{
    font-size: 16px;
  }
  .inquiry-modal {
    padding: 18px 8px;
  }
  .inquiry-modal .d-flex {
    flex-direction: column;
  }
  .inquiry-modal .d-flex div{
    display: flex;
    gap: 12px;
  }
  .inquiry-modal .d-flex div button{
    margin: 0 !important;
  }
  .productQuality-row .quality{
    padding: 0;
    margin-top: .5rem;
  }
  .productQuality-row .quality input{
    width: auto!important;
  }
  .productQuality-row .quality button{
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
}