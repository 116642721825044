.category-card .arrowIcon {
    width: 32px;
    height: 32px;
}
.ar .category-card .arrowIcon {
    transform: rotate(180deg);
}
.category-card .information{
    display: block;
    background-color: var(--orange-color);
    text-align: center;
    padding: 10px;
    border-radius: 0 0 20px 20px;
}
.category-card {
    position: relative;
    height: auto;
    text-decoration: none;
    border-radius: 20px;
}
.category-card  img{
    height: 330px;
    border-radius: 20px 20px 0 0;
}
.category-card .title{
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

@media only screen and (max-width: 850px) {
    .category-card img{
      height: 100px;
    }
}
@media only screen and (max-width: 500px) {}