.categories{
  min-height: 100vh;
  margin-top: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}
.en .categories .products{
  padding-right: 4rem;
  padding-left: 0;
}
.ar .categories .products{
  padding-left: 0;
  padding-right: 4rem;
}
.categories hr{
  color: #D0D0D0;
}

.type-title span{
  font-size: 18px;
  font-weight: 400;
  line-height: 20.93px;
  color: #848484;
}


.filter-section {
  padding: 0 14px 31px;
  background-color: #F8F8F8;
}
.filter-section h5{
  margin-left: 75px;
  padding-top: 32px;
  font-size: 24px;
  line-height: 28.85px;
}
.brands{
  padding-left: 75px;
}
.brands .brand-title{
  color: #848484;
  font-size: 18px;
  margin-bottom: 16px;
  display: block;
  font-weight: 500;
}
.brands .search{
  height: 40px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
}
.brands .brand-labels{
  gap: 8px;
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
}
.brands .brand-labels input{
  height: 18px;
  width: 18px;
  outline: none;
	content: none;	
}
.filter-icon{
  display: none;
}
@media only screen and (max-width: 500px) {
  .filter-icon{
    display: block;
    width: 30px;
    position: absolute;
    top: 160px;
  }
  .en .filter-icon{
    right: 5px;
  }
  .ar .filter-icon{
    left: 5px;
  }
  .filter-section{
    position: absolute;
    z-index: 2;
    top: 144px;
    height: 100%;
    padding: 0 32px !important;
    display: none;
    transition: all .5s ease-in-out;
  }
  .mt-4.col-md-9{
    margin-top: 0!important;
  }
  .filter-section.show{
    display: block;
  }
  .filter-section h5 {
    margin-left: 0!important;
  }
  .brands {
    padding-left: 0!important;
  }
  .en .categories .products {
    padding-right: 0!important;
  }
  .ar .categories .products {
    padding-left: 0!important;
  }
  .product-card .details h6,
  .product-card .details .price{
    font-size: 16px;
  }
}