.checkout_page{
    margin-top: 12rem;
}
.addressModal input{ font-size: 16px; }
.addressModal .modal-title{ 
    font-size: 18px; 
}
.addressModal .modal-dialog{ 
    max-width: 50%;
}
.addressModal .btn-close{ 
    font-size: 12px; 
}
.checkout_page input,
.checkout_page select{
    margin-top: 6px;
}
.sec_head h4{
    font-size: 16px;
}
.sec_head {
    border-bottom: 1px solid #D0D0D0;
    padding-bottom: 8px;
    margin-bottom: 24px;
}

.checkout_page .payment-method{
    background: var(--bg-primary-color);
    padding: 20px 15px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.payment-method h5, 
.cont-pay-dts h5, 
.coupon-code h5 {
    color: #848484;
    margin-bottom: 15px;
    font-size: 16px;
}
.form-coupon .form-group {
    position: relative;
}
.form-coupon .form-control {
    background: #fff;
    border: 0;
    border-radius: 10px;
    height: 41px;
    color: #505153;
}
.form-coupon .btn-site {
    height: 35px;
    position: absolute;
    top: 5px;
    border-radius: 15px;
    background-color: #26847d;
}
.ar .form-coupon .btn-site {
    left: 4px;
}
.en .form-coupon .btn-site {
    right: 4px;
}
.payment-method .list-pay div{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 12px;
}
.ar .payment-method .list-pay div svg,
.ar .payment-method .list-pay div img{
    margin-left: 12px;
}
.en .payment-method .list-pay div svg,
.en .payment-method .list-pay div img{
    margin-right: 12px;
}
.payment-method .list-pay div label{
    color: #111;
    font-size: 14px;
    font-weight: 500;
}
.cont-pay-dts,
.checkout_page .coupon-code{
    background: var(--bg-primary-color);
    padding: 20px 15px;
    border-radius: 10px;
}
.cont-pay-dts .send_form{
    background-color: #fff;
    width: 100%;
    display: block;
    margin-top: 18px;
    height: 40px;
    font-size: 15px;
    padding: 12px 25px;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    color: var(--primary-color);
    border-radius: 30px;
}
.cont-pay-dts div{
    color: #111;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 500;
}
.address label{
    color: #848484;
    font-size: 14px;
}
.address p{
    font-weight: 500;
    font-size: 16px;
}
.product-cart{
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    border-bottom: 1px solid #dedede;
    padding-bottom: 1.5rem;
}
.form-coupon .btn-site {
    height: 35px;
    position: absolute;
    top: 3px;
    line-height: 1;
    border-radius: 15px;
    background-color: var(--primary-color);
}
 .form-coupon .btn-danger {
    height: 33px;
    position: absolute;
    top: 4px;
    border-radius: 35px;
    color: #fff;
    background-color: var(--bs-btn-bg);
    padding: 10px 15px;
}
.discount_amount{
    color: var(--bs-danger);
}
.ar .form-coupon .btn-site,
.ar .form-coupon .btn-danger {
    left: 4px;
}
.en .form-coupon .btn-site,
.en .form-coupon .btn-danger {
    right: 4px;
}
.submit-order{
    background-color: var(--primary-color)!important;
    width: 100%;
    margin-top: 18px;
    color:#fff;
    border: 1px solid var(--primary-color);
}
.submit-order:hover{
    border-color: #fff;
    color: #fff;
    background-color: var(--primary-color)!important;
}
@media only screen and (max-width: 500px) {
    .checkout_page {
        margin-top: 3rem;
    }
    .addressModal .modal-dialog{ 
        max-width: 90%;
        margin: 2rem auto;
    }
}