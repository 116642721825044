.modal-content .modal-body{
    padding: 24px;
}
.modal.show .modal-dialog{
    margin-top: 2rem;
    max-height: 90vh;
    overflow: auto;
    border-radius: 8px;
}
.modal.show .modal-dialog::-webkit-scrollbar {
    width: 0px;
}
.login-modal .modal-dialog{
    max-width: 431px;
}
.login-form .tabs,
.signup-form .tabs{
    margin: 24px auto;
    padding: 11.5px 8px;
    background-color: var(--orange-bg-color);
    display: flex;
    justify-content: center;
    gap: 16px;
    border-radius: 15px;
    width: max-content;
}
.login-form .tabs .tab,
.signup-form .tabs .tab{
    margin: 0;
    font-size: 14px;
    line-height: 18.98px;
    font-weight: 700;
    padding: 6.5px 27px;
    border-radius: 10px;
    cursor: pointer;
}
.upload{
    border: 1px solid #dedede;
    border-radius: 8px;
    padding: 6px 15px;
    margin-top: 8px;
    position: relative;
}
.upload input{
    width: 100%;
    opacity: 0;
    z-index: 9;
    position: relative;
    cursor: pointer;
}
.upload svg,
.upload p{
    margin: 0;
    position: absolute;
    top: 50%;
}
.upload p{
    transform: translate(0, -50%);
}
.ar .upload svg{
    left: 10px;
    transform: translate(0, -50%);
}
.en .upload svg{
    right: 10px;
    transform: translate(0, -50%);
}
.login-form .tabs .tab.active,
.signup-form .tabs .tab.active{
    background-color: var(--primary-color);
    color: #fff;
}
.login-form .title,
.signup-form .title{
   text-align: center;
   font-size: 16px;
   line-height: 19.23px;
   font-weight: 700;
   margin: 0;
}
.login-form .form-control,
.signup-form .form-control{
    min-height: 42px;
    padding: 5px 14px;
}
.login-form .title::after,
.signup-form .title::after{
   content: '';
   width: 13rem;
   height: 2px;
   background-color: #000;
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 8px);
}
.signup-form .title::after{
    width: 9rem;
}
.login-form .login-btn,
.signup-form .signup-btn{
    background-color: var(--primary-color);
    margin-top: 1rem;
    width: 100%;
    padding: 7.5px;
    border-radius: 12px;
}
.login-form .login-btn:hover,
.signup-form .signup-btn:hover{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: .8;
}
.signup,
.login{
    background: none;
    margin-top: 1rem;
    width: 100%;
    padding: 12px;
    color: #000;
    border: none;
}
.signup:hover,
.login:hover{
    background: none;
    color: #000;
}
.signup-btn span {
    margin-left: 1rem;
}
.forget{
    background: none;
    border: none;
    color: blue;
    padding: 0;
    margin-top: 5px;
}
.forget:hover{
    color: rgba(0, 0, 255, 0.57);
}
.showPassword{
    position: absolute;
    top: 2.6rem;
    cursor: pointer;
}
.en .showPassword{
    right: 25px;
    left: auto;
}
.ar .showPassword{
    left: 25px;
    right: auto;
}