.products-section {
  text-align: center;
  position: relative;
  background-color: var(--orange-bg-color);
  padding: 40px 75px;
  margin-bottom: 64px;
}
.products-section h1{
  font-size: 32px;
  line-height: 43.39px;
  font-weight: 700;
}
.products-section .view-all{
  color: var(--primary-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 43.39px;
}
.products-section .mySwiper{
  padding: 0 5px;
}
.products-section .swiper-pagination{
  display: none;
}
.products-section .swiper-wrapper{
  height: auto;
}
@media only screen and (max-width: 500px) {
  .products-section{
    padding: 20px 12px;
  }
  .products-section .view-all{
    font-size: 24px;
  }
}