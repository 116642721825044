.qutaion-card{
    background: #F9F9FB;
    padding: 24px 16px;
    width: 92%;
    border-radius: 8px;
    cursor: pointer;
}
.qutation-details{
    width: 93%
}
.qutation-details .qutation-download button{
    border: 1px solid var(--primary-color);
    background: none;
    border-radius: 8px;
    color: var(--primary-color);
    padding-left: 6.5rem;
    padding-right: 6.5rem;
}
.qutation-details .qutation-details-div{
    background: #F9F9FB;
    border-radius: 8px;
    padding: 16px;
}
.qutation-details .qutation-details-buttons .approve{
    background: var(--primary-color);
    color: #fff;
    border-radius: 8px;
    margin-bottom: .75rem;
    width: 100%;
}
.qutation-details .qutation-details-buttons .reject{
    background: none;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    width: 100%;
}
.qutation-details .qutation-download{
    background-color: var(--orange-bg-color);
    padding: 32px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    border-radius: 8px;
}
.qutation-details .qutation-header{
    background: #F9F9FB;
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;
    border-radius: 8px;
    margin-top: 24px;
}
.ar .qutation-details svg{
    transform: rotate(-180deg);
}
.bg-warning{
    background-color: #F38C35!important;
}