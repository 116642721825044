.product-card {
    border: 1px solid #B9BBC6;
    border-radius: 10px;
    margin-bottom: 15px;
    transition: box-shadow 0.3s ease;
    height: 100%;
    position: relative;
}
.product-card .wishlist{
    position: absolute;
    top: 14px;
    right: 14px;
    width: 40px;
    height: 40px!important;
}
.product-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
}
.product-card .details{
    padding: 12px 7px;
}
.old-price{
    text-decoration: line-through;
    font-weight: 400;
    color: #62636C;
    font-size: 16px;
}
.product-image {
    max-height: 250px;
    height: 250px;
    object-fit: cover;
    width: 100%;
    border-radius: 9px 9px 0 0;
}
  product-card .details{
    border-top: 1px solid #D0D0D0;
}
.product-card .details h6{
    font-size: 18px;
    margin-bottom: 12px;
    font-weight: 700;
    cursor: pointer;
    text-align: start
}
.product-card .details .prices p{
    margin: 0;
}
.product-card .details .prices{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    height: 35.5px;
}
.product-card .details .price{
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 700;
}
.product-card .details .discount{
    font-size: 14px;
    background-color: #FFBD0033;
    color: var(--primary-color);
    line-height: 19.23px;
    padding: 8px 12px;
    font-weight: 700;
    border-radius: 60px;
}
.product-card .details .brand{
    color: #848484;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 16px;
    text-align: start
}
.product-card .details .brand span{
    color: #000;
}
.product-card .details .add-cart{
    background-color: var(--primary-color);
    border-radius: 10px;
    padding: 10px;
    color: #fff;
    font-weight: 700;
    width: 100%;
    font-size: 14px;
    line-height: 18.98px;
}
@media (max-width: 500px) {
    .product-image {
        max-height: 150px;
        height: 150px;
    }
    .product-card{
        overflow: hidden;
    }
    .product-card .details{
        height: calc(100% - 150px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .product-card .details .prices{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 0;
        height: auto;
        flex-direction: column;
    }
}