.top-navbar{
    background-color: var(--navbar-top-bg);
    height: 64px;
    padding: 5px 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.top-navbar .icons{
    display: flex;
    gap: 8px;
}
.top-navbar .icons img{
    cursor: pointer;
}
.top-navbar .language_dropdown{
    /* width: 143px; */
    height: 32px;
}
.top-navbar svg{
    width: 30px;
    /* height: 30px; */
}
.top-navbar .language_dropdown a{
    background: none!important;
}
.navbar{
    position: sticky;
    top: 0;
    background: #fff;
    padding: 5px 75px;
    width: 100%;
    z-index: 5;
    border-bottom: 0.05px solid #D0D0D0;
    height: 100px;
    display: flex;
    justify-content: space-between;
} 
.custom-dropdown ul{
    list-style: none;
}
.navbar .navbar-brand img{
    height: 75px;
    width: 90px;
}
.navbar .one{ order: 1;} 
.navbar .two{ order: 3; display: flex;} 
.navbar .three{ order: 2;} 
.navbar .three input{
    padding: 16px;
} 
.navbar .navbar-nav{
    gap: 16px;
}
.navbar .three .search{
    width: 400px;
    height: 60px;
    border-radius: 60px;
    border: 1px solid #B9BBC6;
}
.navbar a,
.navbar .nav-link{
    color: #000;
    font-size: 18px;
    font-weight: 500;
    padding: 12px 16px;
    line-height: normal
} 
.navbar a:hover{
    color: var(--primary-color) 
}
.navbar a.active{
    color: var(--primary-color) !important;
} 
.navbar-collapse {
    justify-content: flex-end;
}
.navbar .cart-num{
    background-color: #b96464;
    color: #fff;
    position: absolute;
    border-radius: 50%;
    right: -10px;
    top: 0px;
    padding: 0 7px;
    font-size: 14px;
}
.navbar .categories-menu{
    position: absolute;
    top: 100px;
    left: 1%;
    width: 98%;
    background-color: #fff;
    padding: 1rem;
    /* display: none; */
}
.navbar .category-nav-link:hover .categories-menu{
    display: block;
}
/* .navbar .category-nav-link{
    inset: auto;
    transform: none;
}
.navbar .category-nav-link .dropdown-menu{
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0px;
    transform: none!important;
}
.navbar .category-nav-link{} */
@media only screen and (max-width: 500px) {
    .navbar {
        padding: .5rem 8px;
        height: 80px;
    }
    .navbar .one{ order: 1;} 
    .navbar .two{ order: 2;} 
    .navbar .three{ display: none;} 
    .navbar-collapse {
        position: absolute;
        background: #dedede;
        top: 80px;
        z-index: 99999999999;
        padding-top: 16px;
        left: 0;
        width: 100% !important;
        border-bottom: 1px solid #dedede;
    }
    .navbar .nav-link {
        color: #222!important;
    }
    .navbar .navbar-nav {
        gap: 0;
    }
    .navbar-collapse a {
        margin-bottom: 0;
    }
    .navbar .logo img{
        width: 50px;
        height: 50px;
    }
    .top-navbar{
        padding: 5px 8px;
    }
}