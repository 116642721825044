*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --primary-color: #F38B35;
  --bg-primary-color: #F7F9FF;
  --orange-color: #F38B35;
  --orange-bg-color: #f38b3517;
  --footer-bg: #343434;
  --navbar-top-bg: #F38B35;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-primary{
  color: var(--primary-color) !important;
}
.ltr{
  direction: ltr;
}
.rtl{
  direction: rtl;
}
.loader {
    width: 50px;
    margin: auto;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-right-color: var(--primary-color);
    animation: l15 1s infinite linear;
  }
  .loader::before,
  .loader::after {    
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
    animation: l15 2s infinite;
  }
  .loader::after {
    margin: 8px;
    animation-duration: 3s;
  }
  @keyframes l15{ 
    100%{transform: rotate(1turn)}
  }