.categories{
  min-height: 100vh;
  margin-top: 104px;
  overflow: hidden;
}
.categories .products{
  margin-top: 0px;
  padding: 0;
}
.en .categories .products{
  padding-right: 75px;
  padding-left: 0;
}
.ar .categories .products{
  padding-left: 75px;
  padding-right: 0;
}
.categories hr{
  color: #D0D0D0;
}
.categories .type-title{
  font-size: 32px;
  line-height: 43.39px;
  font-weight: 800!important;
  margin-bottom: 24px;
}
.type-title span{
  font-size: 18px;
  font-weight: 400;
  line-height: 20.93px;
  color: #848484;
}


.filter-section {
  padding: 0 14px 31px;
  background-color: #F8F8F8;
}
.filter-section h5{
  margin-left: 75px;
  padding-top: 32px;
  font-size: 24px;
  line-height: 28.85px;
}
.brands{
  padding-left: 75px;
}
.brands .brand-title{
  color: #848484;
  font-size: 18px;
  margin-bottom: 16px;
  display: block;
  font-weight: 500;
}
.brands .search{
  height: 40px;
  border-radius: 10px;
  border: none;
  width: 100%;
  margin-bottom: 16px;
  padding: 8px;
}
.brands .brand-labels{
  gap: 8px;
  display: flex;
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
}
.brands .brand-labels input{
  height: 18px;
  width: 18px;
  outline: none;
	content: none;	
}

@media only screen and (max-width: 500px) {
  .categories{
    margin-top: 24px!important;
  }
  .categories .type-title{
    font-size: 24px;
  }
}