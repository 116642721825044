.categories-home{
  margin-top: 42px;
  margin-bottom: 40px;
  padding: 5px 75px;
}
.categories-home .custom-category{
  background-color: var(--orange-color);
  border-radius: 20px;
  cursor: pointer;
}
.categories-home .custom-category .view-all{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}
@media only screen and (max-width: 850px) {
}
@media only screen and (max-width: 500px) {
  .categories-home{
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0 16px;
  }
  .section-title {
    font-size: 24px!important;
  }
}